import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar2,
} from "../imagepath";

const ChatSideContent = () => {
  return (
    <div className="col-lg-4 d-flex">
      <div className="card chat-box-clinic pt-2">
        <div className="chat-widgets">
          <ConversationItem />
          <ConversationItem />
          <ConversationItem />
          <ConversationItem />
          <ConversationItem />
          <ConversationItem />
          <ConversationItem />
        </div>
      </div>
    </div>
  );
};

const ConversationItem = () => {
  return (
    <div className="chat-user-group d-flex align-items-center cursor-pointer">
      <div className="img-users call-user">
        <Link to="#">
          <img src={Avatar2} alt="img" />
        </Link>
        <span className="active-users bg-info" />
      </div>
      <div className="chat-users">
        <div className="user-titles d-flex">
          <h5> William Stephin </h5>
          <div className="chat-user-time">
            <p>10:22 AM</p>
          </div>
        </div>
        <div className="user-text d-flex">
          <p>Lorem ipsum dolor sit amet...</p>
          <div className="chat-user-count">
            <span>3</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSideContent;
