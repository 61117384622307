/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import {
  TbAdCircleFilled,
  TbLayoutDashboardFilled,
  TbMessageFilled,
  TbUserFilled,
} from "react-icons/tb";
import { FaCalendarCheck, FaFileLines, FaWallet } from "react-icons/fa6";
import { MdCategory, MdPayments } from "react-icons/md";
import { RiBillFill } from "react-icons/ri";
import { BiSolidLogOut } from "react-icons/bi";

const Sidebar = (props) => {
  const [sidebar, setSidebar] = useState("");
  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className
      ? (ulDiv.style.display = "none")
      : (ulDiv.style.display = "block");
    e?.target?.className
      ? div.classList.remove("subdrop")
      : div.classList.add("subdrop");
  };

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, []);

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div
              id="sidebar-menu"
              className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>
                <li
                  style={{marginTop: '12px'}}
                ></li>
                {/* dashboard link */}
                <li className="submenu">
                  <Link
                    to="/admin-dashboard"
                    id="menu-item"
                    onClick={(e) => {
                      handleClick(e, "menu-item", "menu-items");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <TbLayoutDashboardFilled
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName === "admin-dashboard"
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName === "admin-dashboard"
                          ? "text-primary"
                          : ""
                      }
                    >
                      Tableau de bord
                    </span>
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "Dashboard" ? "block" : "none",
                    }}
                    className="menu-items"
                  ></ul>
                </li>
                {/* Gestion utilisateurs */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item1"
                    onClick={(e) => {
                      handleClick(e, "menu-item1", "menu-items1");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <TbUserFilled
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("users")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("users")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Utilisateurs{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "users-lists" ? "block" : "none",
                    }}
                    className="menu-items1"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "tutor-users-lists" ? "active" : ""
                        }
                        to="/users/tutors"
                      >
                        Liste des tuteurs
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "apprenant-users-lists" ? "active" : ""
                        }
                        to="/users/students"
                      >
                        Liste des apprenants
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion Annonces */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item2"
                    onClick={(e) => {
                      handleClick(e, "menu-item2", "menu-items2");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <TbAdCircleFilled
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("announce")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("announce")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Annonces{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "Annonces" ? "block" : "none",
                    }}
                    className="menu-items2"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "announce-all" ? "active" : ""
                        }
                        to="/announcement/all"
                      >
                        Liste des annonces
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion demande */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item3"
                    onClick={(e) => {
                      handleClick(e, "menu-item3", "menu-items3");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <FaFileLines
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("demande")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("demande")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Demandes{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "demande" ? "block" : "none",
                    }}
                    className="menu-items3"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "demande-all" ? "active" : ""
                        }
                        to="/demandes/all"
                      >
                        Liste des demandes
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion catégories */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item4"
                    onClick={(e) => {
                      handleClick(e, "menu-item4", "menu-items4");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <MdCategory
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("categories")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("categories")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Catégories{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "Categories" ? "block" : "none",
                    }}
                    className="menu-items4"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "all-categories" ? "active" : ""
                        }
                        to="/categories/all"
                      >
                        Liste des catégories
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion paiement */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item5"
                    onClick={(e) => {
                      handleClick(e, "menu-item5", "menu-items5");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <MdPayments
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("payment")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("payment")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Paiements{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "payment-all" ? "block" : "none",
                    }}
                    className="menu-items5"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "payment-all" ? "active" : ""
                        }
                        to="/payment/all"
                      >
                        Liste des paiements
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion reservation */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item6"
                    onClick={(e) => {
                      handleClick(e, "menu-item6", "menu-items6");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <FaCalendarCheck
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("reservation")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("reservation")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Réservations{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "reservation" ? "block" : "none",
                    }}
                    className="menu-items6"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "reservation-all" ? "active" : ""
                        }
                        to="/reservation/all"
                      >
                        Liste des réservations
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion contact */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item7"
                    onClick={(e) => {
                      handleClick(e, "menu-item7", "menu-items7");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <TbMessageFilled
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("massage")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("massage")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Messages{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "massage" ? "block" : "none",
                    }}
                    className="menu-items7"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "massage-all" ? "active" : ""
                        }
                        to="/message/all"
                      >
                        Messages reçus
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion soldes */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item8"
                    onClick={(e) => {
                      handleClick(e, "menu-item8", "menu-items8");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <FaWallet
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("soldes")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("soldes")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Soldes{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "soldes" ? "block" : "none",
                    }}
                    className="menu-items8"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "" ? "active" : ""
                        }
                        to="#"
                      >
                        Liste des soldes
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Gestion facturation */}
                <li className="submenu">
                  <Link
                    to="#"
                    id="menu-item9"
                    onClick={(e) => {
                      handleClick(e, "menu-item9", "menu-items9");
                    }}
                  >
                    <span className="menu-side pointer-ignore">
                      <RiBillFill
                        style={{ fontSize: "20px", color: "black" }}
                        className={
                          props?.activeClassName.includes("factures")
                            ? "text-primary"
                            : "text-black-54"
                        }
                      />
                    </span>{" "}
                    <span
                      className={
                        props?.activeClassName.includes("factures")
                          ? "text-primary pointer-ignore"
                          : "pointer-ignore"
                      }
                    >
                      Facturation{" "}
                    </span>{" "}
                    <span className="menu-arrow pointer-ignore" />
                  </Link>
                  <ul
                    style={{
                      display: sidebar === "factures" ? "block" : "none",
                    }}
                    className="menu-items9"
                  >
                    <li>
                      <Link
                        className={
                          props?.activeClassName === "factures-all" ? "active" : ""
                        }
                        to="/facture/all"
                      >
                        Liste des factures
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="logout-btn">
                <Link to="/login">
                  <span className="menu-side pointer-ignore">
                  <BiSolidLogOut
                    style={{ fontSize: "20px", color: "black" }}
                    className='text-black-54'
                  />
                  </span>{" "}
                  <span
                    className='text-black-54 hover-primary'
                  >
                    Déconnexion
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};
export default Sidebar;
