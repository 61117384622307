import { Link } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import PageHeader from "../common/PageHeader";
import { Table } from "antd";
import {
  pdficon,
  pdficon3,
  pdficon4,
  refreshicon,
} from "../imagepath";
import { useState } from "react";
import { onShowSizeChange, itemRender } from "../Pagination";

const datasource = [
  {
    id: "1",
    FactureID: "FAC123456",
    Description: "Subscription",
    Date: "01.10.2022",
    Amount: "$150",
    Status: "Paid",
    IssuedTo: "Andrea Lalema",
  },
  {
    id: "2",
    FactureID: "FAC654321",
    Description: "Subscription",
    Date: "01.10.2022",
    Amount: "$300",
    Status: "Unpaid",
    IssuedTo: "Smith Bruklin",
  },
];

const columns = [
  {
    title: "Facture ID",
    dataIndex: "FactureID",
    sorter: (a, b) => a.FactureID.length - b.FactureID.length,
    render: (text, record) => (
      <>
        <div>
          <Link to="#" className="table-link">
            {record.FactureID}
          </Link>
        </div>
      </>
    ),
  },
  {
    title: "Description",
    dataIndex: "Description",
    sorter: (a, b) => a.Description.length - b.Description.length,
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: (a, b) => a.Date.length - b.Date.length,
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: (a, b) => a.Amount.length - b.Amount.length,
  },
  {
    title: "Status",
    dataIndex: "Status",
    sorter: (a, b) => a.Status.length - b.Status.length,
  },
  {
    title: "Issued To",
    dataIndex: "IssuedTo",
    sorter: (a, b) => a.IssuedTo.length - b.IssuedTo.length,
  },
  {
    title: "",
    dataIndex: "FIELD8",
    render: () => (
      <>
        <div className="text-end">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to="/editfacture">
                <i className="far fa-edit me-2" />
                Edit
              </Link>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete_facture"
              >
                <i className="fa fa-trash-alt m-r-5"></i> Delete
              </Link>
            </div>
          </div>
        </div>
      </>
    ),
  },
];

const AllFacturesPage = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Header />
      <Sidebar id="menu-item" id1="menu-items" activeClassName="factures-all" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <PageHeader title="Factures" subTitle="Factures List" />

            {/* main */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            {/* <h3>
                              Liste des 
                            </h3> */}
                            <div className="doctor-search-blk">
                              <div className="add-group">
                                {/* <Link
                                  to="/add-tutors"
                                  className="btn btn-primary add-pluss"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link> */}
                                <Link
                                  to="#"
                                  className="btn btn-primary doctor-refresh"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <Link to="#" className=" me-2">
                            <img src={pdficon} alt="#" />
                          </Link>
                          <Link to="#" className=" me-2"></Link>
                          <Link to="#" className=" me-2">
                            <img src={pdficon3} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={pdficon4} alt="#" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list px-4">
                      <Table
                        pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={datasource}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AllFacturesPage;
