import { apiClient } from "../api-client"

export class AllApi {
  static async getAllAnnonces(params) {
    const { data } = await apiClient.get("annonces", { params })

    return data.Annonce
  }

  static async getAllById(id) {
    const { data } = await apiClient.get(`users/${id}`)

    return data.data
  }

  static async createAll(data) {
    const response = await apiClient.post("users", data)

    return response.data
  }

  static async updateAll(id, data) {
    const response = await apiClient.put(`users/${id}`, data)

    return response.data
  }

  static async deleteAll(id) {
    const response = await apiClient.delete(`users/${id}`)

    return response.data
  }
  static async deleteAllMany(ids) {
    const response = await apiClient.delete("users", {
      data: { ids },
    })

    return response.data
  }
  // get all reservation
    static async getAllReservation(params) {
        const { data } = await apiClient.get("reservations", { params })
    
        return data.Reservation
    }
    // get all categories
    static async getAllCategories(params) {
        const { data } = await apiClient.get("categories", { params })
    
        return data.Categorie
    }
}
